export const PER_TICKET_COST = 50;
export const TICKET_ID_DIGITS = 4;
export const ALL_SELLERS = [
    {
        seller_id: 1,
        seller_name: 'Team Philcy'
    },
    {
        seller_id: 2,
        seller_name: 'Team Justin'
    },
    {
        seller_id: 3,
        seller_name: 'Team Grejo'
    },
    {
        seller_id: 4,
        seller_name: 'Team Alen'
    },
    {
        seller_id: 5,
        seller_name: 'Team Jestin'
    },
    {
        seller_id: 6,
        seller_name: 'Team Anu'
    },
];
