import LotteryTickets from "../components/Tickets/LotteryTickets"

const LotteryPage = () => {
  return (
    <div id="main">
        <LotteryTickets />
    </div>
  )
}

export default LotteryPage
